exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-templates-blog-archive-template-jsx": () => import("./../../../src/templates/blogArchiveTemplate.jsx" /* webpackChunkName: "component---src-templates-blog-archive-template-jsx" */),
  "component---src-templates-category-archive-template-jsx": () => import("./../../../src/templates/categoryArchiveTemplate.jsx" /* webpackChunkName: "component---src-templates-category-archive-template-jsx" */),
  "component---src-templates-page-template-jsx": () => import("./../../../src/templates/pageTemplate.jsx" /* webpackChunkName: "component---src-templates-page-template-jsx" */),
  "component---src-templates-post-template-jsx": () => import("./../../../src/templates/postTemplate.jsx" /* webpackChunkName: "component---src-templates-post-template-jsx" */),
  "component---src-templates-recipes-archive-template-jsx": () => import("./../../../src/templates/recipesArchiveTemplate.jsx" /* webpackChunkName: "component---src-templates-recipes-archive-template-jsx" */),
  "component---src-templates-recipes-category-archive-template-jsx": () => import("./../../../src/templates/recipesCategoryArchiveTemplate.jsx" /* webpackChunkName: "component---src-templates-recipes-category-archive-template-jsx" */),
  "component---src-templates-recipes-template-jsx": () => import("./../../../src/templates/recipesTemplate.jsx" /* webpackChunkName: "component---src-templates-recipes-template-jsx" */)
}

